<template>
  <div class="seats">
    <p :style="{color : companyData.companyColor ? companyData.companyColor : ''}"
       class="heading pb-5">Select Seats</p>
    <div class="d-flex">
      <!-- desktop -->
      <v-expansion-panels class="desktop" v-model="panel" flat readonly disabled>
        <v-expansion-panel  >
          <v-expansion-panel-header>
            <v-row class="tw-items-center ">
              <v-col lg="3" sm="6" md="3" class="tw-flex tw-w-full tw-items-center tw-flex-row">
                <img :src="userSelectedTrip.fleetForThisTrip.vehicleImage" alt="" class="company-logo">
                <p class="company-name text-capitalize ml-7 mt-3"> {{getFirstText(userSelectedTrip.fleetForThisTrip.vehicleBrand)}} </p>
              </v-col>
              <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                <p class="route uppercase text-uppercase">
                  {{userSelectedTrip.routeDepartureCity.substring(0,3)}}-{{userSelectedTrip.routeDestinationCity.substring(0,3)}} </p>
              </v-col>
              <v-col lg="3" sm="6" md="3" class=" tw-w-full tw-items-center">
                <p class="departure-time"> {{getDateTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}} </p>
              </v-col>
              <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center tw-justify-center">
                <p class="seats-available"> {{getAvailableSeats(userSelectedTrip.fleetForThisTrip.seats)}} Seats Available</p>

              </v-col>
              <v-col lg="1" sm="6" md="1" class=" tw-w-full tw-items-center">
                <p class="departure-time"> ₦{{formatNumber(userSelectedTrip.tripAmount)}} </p>

              </v-col>
            </v-row>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="dropdown tw-flex">

              <div style="width:30px" class="group"><img src="@/assets/from-to.svg" alt=""></div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show text-capitalize"> {{getFirstText(userSelectedTrip.routeDepartureState)}}
                      ({{toSentenceCase(userSelectedTrip.routeDepartureCity)}} - {{toSentenceCase(userSelectedTrip.routeDepartureTerminalName)}})</p>
                    <p class=" hide text-capitalize"> {{toSentenceCase(userSelectedTrip.routeDepartureTerminalName)}}</p>

                  </div>
                  <div class="right">
                  <span :style="{color : companyData.companyColor ? companyData.companyColor : ''}"
                        class="amount tw-hidden md:tw-flex"> ₦{{formatNumber(userSelectedTrip.tripAmount)}} </span>
                    <p v-if="userSelectedTrip.discount && userSelectedTrip.discount.id" style="color: red" class="trip-discount tw-mt-5 ">
                      Book now and enjoy <span v-if="userSelectedTrip.discount.type === 'FIXED'">&#8358;</span>{{formatNumber(userSelectedTrip.discount.unitAmount)}}<span v-if="userSelectedTrip.discount.type === 'PERCENTAGE'">%</span> discount
                    </p>
                  </div>
                </div>
                    <div class="second-line my-3">
                        <div class="left tw-flex tw-items-center">
                            <span class="time-etimate">(Stops) {{userSelectedTrip.tripStops}}</span>
                        </div>

                  <div class="right">
                  <span class="available-seats  tw-hidden md:tw-flex"> Available Seats : {{getAvailableSeats(userSelectedTrip.fleetForThisTrip.seats)}}
                  </span>
                  </div>

                </div>
                <div class="third-line mt-7 tw-flex">
<!--                  <span>{{getTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}}</span>-->
                  <span class="show text-capitalize">{{getFirstText(userSelectedTrip.routeDestinationState)}}
                ({{toSentenceCase(userSelectedTrip.routeDestinationCity)}} - {{toSentenceCase(userSelectedTrip.routeDestinationTerminalName)}})</span>
                  <span class="hide text-capitalize">{{toSentenceCase(userSelectedTrip.routeDestinationTerminalName)}}</span>
                </div>
                <span class="available-seats second-as  tw-flex md:tw-hidden my-5"> Available Seats :
                {{getAvailableSeats(userSelectedTrip.fleetForThisTrip.seats)}}
              </span>
                <div class="footer my-5 ">
                  <span class="bus-type"> {{userSelectedTrip.fleetForThisTrip.vehicleType}}</span>
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class"> {{userSelectedTrip.fleetForThisTrip.vehicleClass}}</span>
                </div>
              </div>
            </div>

          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>

      <!-- //mobile -->
      <v-expansion-panels class="mobile" readonly v-model="panel" flat disabled>
        <v-expansion-panel  flat>
          <v-expansion-panel-header>
            <div class="tw-flex">
              <div class="up-left">
                <img :src="userSelectedTrip.fleetForThisTrip.vehicleImage" alt="" class="company-logo">
              </div>
              <div class="up-right ">
                <v-row class="ml-1 justify-between">
                  <v-col sm="6" class=" tw-items-center ">

                    <p class="company-name text-capitalize mt-3"> {{getFirstText(userSelectedTrip.fleetForThisTrip.vehicleBrand)}} </p>
                  </v-col>
                  <v-col lg="2" sm="6" md="2" class=" tw-w-full tw-items-center">
                    <p class="route text-uppercase"> {{userSelectedTrip.routeDepartureCity.substring(0,3)}} - {{userSelectedTrip.routeDestinationCity.substring(0,3)}}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col sm="6" class=" tw-items-center">
                    <p class="departure-time"> {{getDateTime(userSelectedTrip.takeOffDate,userSelectedTrip.takeOffTime)}} </p>
                  </v-col>
                  <v-col sm="6" class=" tw-w-full tw-items-center">
                    <p :style="{color : companyData.companyColor ? companyData.companyColor : ''}"
                       class="amount"> ₦{{formatNumber(userSelectedTrip.tripAmount)}} </p>
                  </v-col>
                </v-row>
              </div>
            </div>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="userSelectedTrip.discount && userSelectedTrip.discount.id" style="color: red" class="group trip-discount tw-mt-5 ">
              Book now and enjoy <span v-if="userSelectedTrip.discount.type === 'FIXED'">&#8358;</span>{{formatNumber(userSelectedTrip.discount.unitAmount)}}<span v-if="userSelectedTrip.discount.type === 'PERCENTAGE'">%</span> discount
            </p>
            <div class="dropdown tw-flex">

              <div style="width:30px" class="group"><img src="@/assets/from-to.svg" alt=""></div>
              <div class="main-left">
                <div class="first-line ">
                  <div class="left tw-flex">
                    <p class=" show text-capitalize"> {{getFirstText(userSelectedTrip.routeDepartureState)}}
                      ({{toSentenceCase(userSelectedTrip.routeDepartureCity)}} - {{toSentenceCase(userSelectedTrip.routeDepartureTerminalName)}})</p>
                    <p class=" hide text-capitalize">{{getFirstText(userSelectedTrip.routeDepartureState)}} ({{toSentenceCase(userSelectedTrip.routeDepartureTerminalName)}})</p>

                  </div>
                  <div class="right">
                  <span :style="{color : companyData.companyColor ? companyData.companyColor : ''}"
                        class="amount tw-hidden md:tw-flex"> ₦{{formatNumber(userSelectedTrip.tripAmount)}} </span>
                  </div>
                </div>
                <div class="second-line tw-pb-1 tw-mt-3">
                    <div class="left tw-flex tw-items-center tw-flex-wrap tw-mt-5">
                        <span class="time-etimate">(Stops) {{userSelectedTrip.tripStops}}</span>
                    </div>
                  <div class="right">
                  <span class="available-seats  tw-hidden md:tw-flex"> Available Seats : {{getAvailableSeats(userSelectedTrip.fleetForThisTrip.seats)}}
                  </span>
                  </div>

                </div>
                <div class="third-line mt-7 tw-flex">
                  <span class="show text-capitalize ">{{getFirstText(userSelectedTrip.routeDestinationState)}}
                ({{toSentenceCase(userSelectedTrip.routeDestinationCity)}} - {{toSentenceCase(userSelectedTrip.routeDestinationTerminalName)}})</span>
                  <span class="hide text-capitalize ">{{getFirstText(userSelectedTrip.routeDestinationState)}} ({{toSentenceCase(userSelectedTrip.routeDestinationTerminalName)}})</span>
                </div>
                <span class="available-seats second-as  tw-flex md:tw-hidden tw-mt-10"> Available Seats :
                {{getAvailableSeats(userSelectedTrip.fleetForThisTrip.seats)}}
              </span>
                <div class="footer my-5 ">
                  <span class="bus-type"> {{userSelectedTrip.fleetForThisTrip.vehicleType}}</span>
                  <span class="mx-2 dot">.</span>
                  <span class="bus-class"> {{userSelectedTrip.fleetForThisTrip.vehicleClass}}</span>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
  </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import dayjs from "dayjs";

  export default {
    components: {
    },
    name: "UserSelectedTripInfo",
    data() {
      return {
        panel: 0,
          selectedTrip: [],
          selectedTripBtn: false
        }
      },
     computed:{
      ...mapGetters("companyProfile",["companyData"]),
       ...mapGetters("tripAndTravellerDetails", ["userSelectedTrip"]),
       ...mapGetters("seatsSelection",["seats"])
     },
    methods:{
      getTime(date,time){
        return dayjs(date+time).format('LT')
      },
      getDateTime(date,time){
        return dayjs(date+time).format("lll")
      },
      getAvailableSeats(seats){
        let availableSeats = 0
        let unavailableSeats = 0
        seats.forEach(seat =>{
          if(seat.status === "AVAILABLE"){
            availableSeats += 1
          }
          else {
            unavailableSeats +=1
          }
        })

        if(this.seats && unavailableSeats > 0 ){
          return availableSeats + unavailableSeats -1 -  unavailableSeats
        }
        else {
          return  availableSeats - 1
        }

      },
        toSentenceCase(text){
            text = text.toLowerCase()
            return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
        },
        getFirstText(text){
            if (text) {
                let arrayText = text.split(" ")
                return this.toSentenceCase(arrayText[0])
            }
        },
      formatNumber(number){
        if(number){
          return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        }
      },
    }
    }
</script>

<style lang='scss' scoped>
  .heading {
    font-family: var(--fontInter);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #004AAD;

  }

  .desktop {
    @media (max-width:960px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media (max-width:960px) {
      display: flex;
    }
  }


    .seats {
      margin: 5rem 11rem 5rem;

      @media (max-width:1264px) {
        margin: 5rem 2rem 5rem;
      }

      @media (max-width:576px) {
        margin: 5rem 1rem 1rem;
      }


    }

  .v-expansion-panel-header {
    @media (max-width:960px) {
      padding: 8px 12px !important;
    }
  }

  .col {
    padding: 5px;
  }

  .text-design {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .v-select {
    width: 150px !important;

    @media (max-width:567px) {
      width: 70px !important;
    }
  }


  .departure {
    font-family: var(--fontInter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #004AAD;

    @media (max-width:960px) {
      font-size: 13px;
    }
  }

  .sort,
  .v-list-item__title {
    font-family: var(--fontInter);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

    @media (max-width:960px) {
      font-size: 10px;
    }
  }

  .available-buses {
    display: flex;
    justify-content: space-evenly;
  }

  .company-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: contain;
    @media (max-width:960px) {
      width: 40px;
      height: 40px;
    }
  }

  .company-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #4F4F4F;
  }

  .route,
  .departure-time,
  .seats-available,
  .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 15px;
    line-height: 17px;
    color: #4F4F4F;

    @media (MAX-WIDTH:960px) {
      font-size: 11px;

    }

  }

  .v-application p {
    margin-bottom: 0 !important;
  }

  .cta {
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .book {
    color: #FAFAFA;

    width: 123px;
    height: 37px;
    background-color: #004AAD;
    border-radius: 4px;

    @media (max-width: 576px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .button-handler {
    align-items: flex-start;
    margin: 20px 0;

    @media (max-width:1520px) {
      flex-direction: column;
    }

    @media (max-width:960px) {
      flex-direction: row !important;
      margin: 20px 0 40px 80px;
    }

    @media (max-width:768px) {
      margin: 20px 0 40px 53px;

    }

  }

  .schedule {
    color: #004aad;

    width: 123px;
    height: 37px;
    border: 1px solid #004AAD;
    border-radius: 4px;
    margin-left: 10px;

    @media (max-width:1520px) {
      margin-left: 0;
      margin-top: 20px;
    }

    @media (max-width:960px) {
      margin-top: 0;
      margin-left: 15px;

    }

    @media (max-width:576px) {
      width: 100%;
      margin-left: 0;
    }


  }

  .up-left {
    width: 70px;

  }

  .up-right {
    width: 100%;
  }

  .dropdown {
    margin-top: 30px;
  }

  .group {
    margin-left: 80px;

    @media (max-width:768px) {
      margin-left: 59px;
    }
  }

  .show {
    display: flex;

    @media (max-width:576px) {
      display: none
    }
  }

  .hide {
    display: none;

    @media (max-width:576px) {
      display: flex
    }
  }

  .main-left {
    width: 90%;

    }

  .first-line,
  .second-line {
    display: flex;
    justify-content: space-between !important;
  }

  .dot {
    font-size: 15px;
  }

  .first-line .left,
  .third-line {

    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;

  }

  .amount {
    color: #004aad;
  }

  .first-line .right .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: #004AAD;

  }

  .second-line,
  .footer,
  .second-as {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;

  }

  .second-as {
    margin-left: -30px;
  }

  .footer {
    color: #878787;
    margin-left: -30px;
    line-height: 20px;

  }

  ::v-deep .v-text-field input {
    padding: 0 !important;
  }

  :deep(.v-expansion-panel-header__icon ) {
    display: none !important;
  }
 
</style>